<template>
  <div id="employee-new-modal">
    <vs-popup class="popup50" :title="title" :active.sync="localShow">

      <div class="vx-row">
        <div class="vx-col md:w-full w-full mt-0">
          <vs-input class="w-full mt-0" label="Grupo" v-model="group.group" data-vv-as="Grupo" v-validate.initial="'required'" name="group" />
          <span class="text-danger text-sm" v-show="errors.has('group')">{{ errors.first('group') }}</span>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm">Cadastrar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    }
  },

  computed: {
    validateForm () {
      return !this.errors.any()
    }
  },

  data () {
    return {
      localShow: false,
      group: {
        group: null
      }
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.group = {
          group: null
        }
        this.$emit('cancel')
      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('groups/store', this.group)
        this.$vs.loading.close()
        this.action()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup50 .vs-popup {
    width: 50% !important;
  }

  .vue_select_drop_size_200 .vs__dropdown-menu {
    max-height: 200px;
  }
</style>
